import { Controller } from '@hotwired/stimulus'
// import { createPopper } from '@popperjs/core';
import { arrow, computePosition, flip, offset, shift } from '@floating-ui/dom'

// Stimulus controller for the Popper.js library
export default class extends Controller {
  static targets = ['arrow', 'content', 'trigger']
  static values = {
    // Set the placement of the tooltip. See
    // https://floating-ui.com/docs/computePosition#placement for possible
    // values.
    placement: { type: String, default: 'bottom' }
  }

  connect () {
    if (this.hasTriggerTarget) {
      this.updateTooltip()

      this.element.addEventListener('mouseenter', this.show.bind(this))
      this.element.addEventListener('focus', this.show.bind(this))

      this.element.addEventListener('mouseleave', this.hide.bind(this))
      this.element.addEventListener('blur', this.hide.bind(this))
    }
  }

  disconnect () {
    this.element.removeEventListener('mouseenter', this.show.bind(this))
    this.element.removeEventListener('focus', this.show.bind(this))

    this.element.removeEventListener('mouseleave', this.hide.bind(this))
    this.element.removeEventListener('blur', this.hide.bind(this))
  }

  hide () {
    this.contentTarget.classList.add('hidden')
  }

  show () {
    this.contentTarget.classList.remove('hidden')
    this.updateTooltip()
  }

  updateTooltip () {
    computePosition(
      this.triggerTarget,
      this.contentTarget,
      {
        placement: this.placementValue,
        middleware: [
          offset(10),
          shift(),
          flip(),
          arrow({ element: this.arrowTarget })
        ]
      }
    ).then(({ x, y, placement, middlewareData }) => {
      Object.assign(this.contentTarget.style, {
        left: `${x}px`,
        top: `${y}px`
      })

      // Place the arrow
      const { x: arrowX, y: arrowY } = middlewareData.arrow
      const staticSide = {
        bottom: 'top',
        left: 'right',
        right: 'left',
        top: 'bottom'
      }[placement.split('-')[0]]

      Object.assign(this.arrowTarget.style, {
        bottom: '',
        left: arrowX != null ? `${arrowX}px` : '',
        right: '',
        top: arrowY != null ? `${arrowY}px` : '',
        [staticSide]: '-4px'
      })
    })
  }
}
