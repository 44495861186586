import { Controller } from "@hotwired/stimulus"

// Targets
//
// - input: the input field we'll watch for changes and update the results with.
//   The input is expected to belong to a form that is submitted as a Turbo
//   Stream request.
export default class extends Controller {
  static targets = ["input"]

  static values = {
    // The maximum number of seconds to wait before refreshing the inbox after a
    // conversation is updated.
    debounceSeconds: { type: Number, default: 10 }
  }

  clearRefreshTimer() {
    if (!this.refreshTimer) { return }

    clearTimeout(this.refreshTimer)
  }

  connect() {
    this.inputTarget.addEventListener("keyup", this.handleInputChanged.bind(this))

    document.addEventListener("skrift:conversation:updated", this.handleConversationUpdated.bind(this))
  }

  disconnect() {
    if (this.hasInputTarget) {
      this.inputTarget.removeEventListener("keyup", this.handleInputChanged.bind(this))
    }

    document.removeEventListener("skrift:conversation:updated", this.handleConversationUpdated.bind(this))

    this.clearRefreshTimer()
  }

  handleConversationUpdated(event) {
    this.clearRefreshTimer()

    // Wait a while before refreshing the inbox, hopefully distributing the load
    // on the server a bit over the next minute.
    const interval = Math.floor(Math.random() * this.debounceSecondsValue * 1000)
    this.refreshTimer = setTimeout(() => {
      this.submitForm()
    }, interval)
  }

  handleInputChanged(event) {
    this.performSearch(this.inputTarget.value)
  }

  performSearch(query) {
    if (query.length == 0 || query.length > 2) {
      this.submitForm()
    }
  }

  submitForm() {
    this.inputTarget.form.requestSubmit()
  }
}
