import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["contents"]

  closeWithKeyboard(event) {
    const escapeKey = 27
    if (event.keyCode === escapeKey) {
      this.dismiss();
    }
  }

  connect() {
    this.element.addEventListener("turbo:submit-end", this.handleSubmitEnd.bind(this))
  }

  disconnect() {
    this.element.removeEventListener("turbo:submit-end", this.handleSubmitEnd.bind(this))
  }

  dismiss() {
    this.element.remove()
  }

  handleClick(event) {
    // Only dismiss if the click was outside the modal contents
    if (event.target === this.contentsTarget || this.contentsTarget.contains(event.target)) return;

    this.dismiss()
  }

  handleSubmitEnd(event) {
    if (event.detail.success) {
      const response = event.detail.fetchResponse.response
      if (response.redirected) {
        document.location = response.url
      } else {
        this.dismiss()
      }
    }
  }
}
