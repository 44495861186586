import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["messageBody"]

  appendToMessageBody(e) {
    const messageTemplate = e.currentTarget.title
    const formattedMessageTemplate = messageTemplate + " "
    this.messageBodyTarget.value += formattedMessageTemplate
    this.scrollToBottom()
  }

  scrollToBottom() {
    this.messageBodyTarget.scrollTop = this.messageBodyTarget.scrollHeight;
  }
}
